import React from "react";
import ReactDOM from "react-dom";
import { Route } from "react-router";
import { BrowserRouter, Switch } from "react-router-dom";

import { AuthenticationProvider } from "@authentication";
import { PrivacyPolicy } from "@pages/PrivacyPolicy";
import { TermsAndConditions } from "@pages/TermsAndConditions";
import App from "./App";

import "@styles";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/">
          <AuthenticationProvider>
            <App />
          </AuthenticationProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
